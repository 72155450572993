import React, { Component } from 'react';
import Header from '../components/Header.js';
import Image_slider from './Image_slider.js';
import Footer1 from './Footer1.js';
import './Pricing.css';
import './Contact_us.css';
class Home extends Component {
    render() {
        return (
        <div>
            <Header />
            <div >
                <Image_slider />
                 <section className="aboutsecetion1">
                    <div className="aboutbgcolor">
                        <div className="container clearfix ">
                            <div className="pull-left head-sec abouts_heading1">
                                <h2>ABOUT US</h2>
                                <span className="aboutspn1"></span>
                            </div>
                            <div className="pull-right head-sec aboutus_btn1">
                            </div>
                        </div>
                    </div>

                </section>
                <br />
                <section >
                    <div className="container clearfix">
                        <div className="col-sm-6 pull-left head-sec">

                            <p>ADO GROUP, Established in JAIPUR 2013 as exhibition, event management
                                and audio visual service Provider. It offers comprehensive conference
                                services to the needs of both local and international markets. ADO GROUP,
                                boasts a strong, efficient and specialized team that has largely contributed to
                                its success..</p>
                            <p>ADO GROUP, has been created to meet up the rapid global growth of the
                                MICE industry – form of tourism largely associated with business purposes –
                                and also, as a response to the increasing demand for specialization in the
                                meeting, conference and exhibition planning field in Jaipur and the
                                Rajasthan state.</p>
                             <p>ADO GROUP, will help you organize your corporate and social events from
                                concept to reality, or simply take charge of individual items of logistics.
                                We believe that choosing ADO GROUP as your partner to organize your next
                                event, will guarantee quality and actualize a cost-effective, well organized
                                and highly successful event each time.</p> 

                        </div>
                        <div className="pull-right head-sec col-sm-6 ">
                            <img alt="" src="https://adogroup.in/about_us.jpg" className="aboutimg" ></img>
                        </div>
                    </div>
                </section>


                <div className="headings">
                    <div className="container">
                        <h4>Services</h4>
                        <span className="lession_span"></span>
                    </div>
                </div>

                <table className="table table-bordered container">
                    <tbody>

                        <tr>
                            <td>Conference Management</td>
                            <td>Full Consultation and Conceptual Planning of the Event, in Accordance to an Action Plan</td>
                        </tr>


                        <tr>
                            <td>Corporate Events</td>
                            <td>Social events (Farewell evenings, anniversary celebrations, Inaugurations or company Parties/Gatherings).</td>
                        </tr>
                        <tr>
                            <td>Audio Visual Equipment</td>
                            <td>Sound systems (Speakers, Mixers,Amplifiers)</td>
                        </tr>

                        <tr>
                            <td>Lighting</td>
                            <td>Lighting mixer,Fresnel lanterns,Spotlights,City Colour</td>
                        </tr>
                        <tr>
                            <td>Sound System</td>
                            <td>Source rour lighting,CD players, Audio recording,DJ rack</td>
                        </tr>
                        <tr>
                            <td>Event lighting equipment</td>
                            <td>Par can (LED,Halogen)</td>
                        </tr>
                    </tbody>
                </table>



               
                <div className="aboutbgcolor">
                    <div className="clearfix container">

                        <h4>Our Mission</h4>
                        <span className="aboutspn2"></span>
                    </div>
                </div><br />
                <div className="clearfix container">
                    <div className=" missionText ">
                        <ul className="list-unstyled">
                            <li>To provide professional, informed, and comfortable services </li>
                            <li>Encore Events & Entertainment, Inc. is dedicated to presenting exceptional service and extraordinary programs for every client.</li>
                            <li>Our mission is to provide the finest service, event planning, and creative cuisine available.</li>
                            <li>To provide innovative and professional marketing and event management services with a focus on quality and detail.</li>
                        </ul>
                    </div>
                </div>
                 <div className="headings">
                    <div className="container">
                        <h4>Our Clients</h4>
                        <span className="lession_span"></span>
                    </div>
                </div>

                <table className="table table-bordered container">
                    <tbody>

                        <tr>
                            <td>ADANI SOLAR</td>
                            <td>RADISSION BLU</td>
                            <td>HOLIDAY INN </td>
                        </tr>
                        <tr>
                            <td>NARYANA HOSPITAL</td>
                            <td>ICICI BANK</td>
                            <td>FINCARE SMALLBANK</td>
                        </tr>
                        <tr>
                            <td>AU SMALL BANK</td>
                            <td>EUREKA FORBES</td>
                            <td>CATPILLER</td>
                        </tr>
                        <tr>
                            <td>HONDA</td>
                            <td>INDIAN OIL</td>
                            <td>JK CEMENT</td>
                        </tr>
                        <tr>
                            <td>STEELBARRY</td>
                            <td>HP</td>
                            <td>FIEO</td>
                        </tr>
                         <tr>
                            <td>Jaipur Dental</td>
                            <td>Reserve Bank of India</td>
                            <td>BMCH Hospital</td>
                        </tr>
                         <tr>
                            <td>SDMH Hospital</td>
                            <td>Intas Pharma</td>
                            <td>BULID SMART</td>
                        </tr>

                    </tbody>
                </table>
                <div className="aboutbgcolor">
                    <div className="container">

                        <h4>Our Team</h4>
                        <span className="aboutspn3"></span>
                    </div>
                </div>
                <div className="container aboutourteamimg">
                    <div className="row container">
                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-0 shadow hover-zoomin">
                                <img src="https://adogroup.in/ceo.jpg" class="card-img-top" alt="..." />
                                <div className="card-body text-center">
                                    <h5 className="card-title mb-0">Raj Duttatria</h5>
                                    <div className="card-text text-black-50">CEO
                                            </div>
                                </div>

                            </div>
                        </div>

                       
                    </div>
                </div>





                <div className="headings">
                    <div className="container">
                        <h2>CONTACT US</h2>
                        <span className="contact_span"></span>
                    </div>
                </div><br />

                <div className="container">
                    <div className="img-txt">
                        <div className="photo" >
                            <img src="https://adogroup.in/photo5.jpg" className="img" />
                        </div>


                        <div className="content">
                            <p>Thank you for your interest in Ado Group in Jaipur, Rajasthan! We are pleased that you took
                                the time to visit our website and look forward to talking with you in person. We think you’ll be pleasantly
                                        surprised with the ease and affordability of our services.</p>
                            <p>Please use the contact information below to send us an email and take the first step towards us to serve you the better services</p>
                        </div>

                        <br />
                    </div>
                </div>
                




                <Footer1 />

            </div>
        </div>

        );
    }
}
export default Home;