import { takeEvery,call , put} from "redux-saga/effects"

export default function* RegisterSaga(){
    console.log("data RegisterSaga ")
    yield takeEvery ("REGISTER",authorize)
    yield takeEvery ("VERIFYOTP",authorizeOTP)
    // const {payload} = yield take('REGISTER');
    
}

 function* authorize(action) {
   
    try {
      const getApiData = yield call(getData, action)
      if(getApiData.data){
        yield put({ type: "REGISTER_SUCCESS", getApiData });
        console.log("d<><>>REGISTER_SUCCESS<",getApiData);
      }else{
        console.log("dREGISTER_FAILED",getApiData);
        yield put({ type: "REGISTER_FAILED", getApiData });
     
      }
      
    } catch(error) {
        yield put({ type: "REGISTER_FAILED", error });
    }   
  }


 function* authorizeOTP(action) {
   
  try {
    const getApiData = yield call(getOTP, action)
    if(getApiData.data){
      yield put({ type: "VERIFYOTP_SUCCESS", getApiData });
    console.log("d<><>>VERIFYOTP_SUCCESS<",getApiData);
    }else{
      console.log("VERIFYOTP ",getApiData);
      yield put({ type: "VERIFYOTP_FAILED", getApiData });
    
    }
    
  } catch(error) {
      yield put({ type: "VERIFYOTP_FAILED", error });
  }   
}

function getOTP (data){
  console.log(">>>>>>>>>><getOTP<<<<<<<<<<<<< ",data.payload)
  const OTP = data.payload
  console.log("email >>>>>>getOTP>>>>>>>> "+OTP);
  if(OTP==="000000"){  
    return {data:"Verified OTP"}
  }else{
    return {data:"invalid OTP"}
  }
  

}
function getData (data){
    console.log(">>>>>>>>>><<<<<<<<<<<<<< ",data.payload)
    const {email, mobile} = data.payload
    console.log("email >>>>>>>>>>>>>> "+email)
    if(mobile==="0809455601" && email==="amit@annexlogics.com"){
      return fetch("https://jsonplaceholder.typicode.com/posts").then(response =>
      response.json()
      );
    }else{
      return {data:"invalid credential"}
    }
    
  
}
