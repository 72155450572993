function ContactUsReducer(state = {user:[],status:""}, action) {
        
    switch (action.type) {
        case "CONTACTUS_SUCCESS":
            console.log("CONTACTUS_SUCCESS",action.getApiData);
                return {
                    ...state,
                    status:"",
                    user: action.getApiData
                      }; 
        case "CONTACTUS_FAILED":
            console.log("CONTACTUS_FAILED ",action.getApiData.data);
            return {
                ...state,
                    status:action.getApiData.data,
                    user:[]
                };
                      
        default:
          return state
      }
}
export default ContactUsReducer