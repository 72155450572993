function RegisterReducer(state = {user:[],status:""}, action) {
    console.log("action type in reg reducer ",action)
    switch (action.type) {
        case "REGISTER_SUCCESS":
            console.log("REGISTER_SUCCESS",action.getApiData);
                return {
                    ...state,
                    status:"",
                    user: action.getApiData
                      }; 
        case "REGISTER_FAILED":
            console.log("REGISTER_FAILED ",action.getApiData.data);
            return {
                ...state,
                    status:action.getApiData.data,
                    user:[]
                };
        case "VERIFYOTP_SUCCESS":
            console.log("VERIFYOTP_SUCCESS ",action.getApiData.data);
            return {
                ...state,
                    status:action.getApiData.data,
                };
        case "VERIFYOTP_FAILED":
            console.log("VERIFYOTP_FAILED ",action.getApiData.data);
            return {
                ...state,
                    status:action.getApiData.data,
                };     
        default:
          return state
      }
}
export default RegisterReducer