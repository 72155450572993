import React, { Component } from "react";
import './Image_slider.css';


class Image_slider extends Component {
    render() {
        return (
            <div className="container" >
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <ul className="carousel-indicators">
                        <li data-target="#carouselExampleControls" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleControls" data-slide-to="1"></li>
                        <li data-target="#carouselExampleControls" data-slide-to="2"></li>
                    </ul>

                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img alt="" src="https://adogroup.in/photo1.jpg" className="img" />

                            <div className="carousel-caption d-none d-md-block ">
                                <div className="name_on_images">
                                   <p>Innovative Audio Visual Services</p>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img alt="" src="https://adogroup.in/photo2.jpg" className="img" />
                            <div className="carousel-caption d-none d-md-block ">
                                <div className="name_on_images">
                                    <p>Innovative Audio Visual Services</p>
                                </div>
                            </div>

                        </div>
                        <div className="carousel-item" >
                            <img alt="" src="https://adogroup.in/photo3.jpg" className="img" />
                            <div className="carousel-caption d-none d-md-block ">
                                <div className="name_on_images">
                                    <p>Innovative Audio Visual Services</p>
                                </div>

                            </div>

                        </div>
                    </div>

                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
        );
    }
}

export default Image_slider;