import React from   'react';

class Footer1 extends React.Component{
    render() {
        return (
            <div>
                <footer className="footerstyle1" >
                    <div className ="container" >
            <div className="row container">
                <div className="col-md-4 text-center text-md-left ">
                    
                    <div className="py-2">
                        <h5 className="my-3 text-white">About<span className="mx-2 font-italic text-warning ">Ado Group</span></h5>
 
                        <p className="footer-links ">
                            <a className="text-white" href="/">Home</a>
                            |
                            <a className="text-white" href="/">About</a>
                            |
                            <a className="text-white" href="/">Pricing</a>
                            |
                            <a className="text-white" href="">Contact</a>
                        </p>
                        <p className="text-light py-4 mb-4">&copy;2013 Ado group</p>
                    </div>
                </div>
                
                <div className="col-md-4 text-white text-center text-md-left ">
                    <div className="py-2 my-3">
                        <div>
                            <p className="text-white"> <i className="fa fa-map-marker mx-2 "></i>Nirman  Nagar, Jaipur,  302019</p>
                        </div>
 
                        <div> 
                            <p><i className="fa fa-phone  mx-2 "></i><a href="tel:+919521178777"  className="footer_numbercolor">+91 9521 178 777</a></p>
                        </div>
                        <div>
                            <p><i className="fa fa-envelope  mx-2"></i><a href="https://mail.google.com/"  className="footer_numbercolor">Info.adogroup@gmail.com</a></p>
                        </div>  
                    </div>  
                </div>
                
                <div className="col-md-4 text-white my-3 text-center text-md-left ">
                    <span className=" font-weight-bold ">About the Company</span>
					<p className="text-warning my-2" >We will help you organize your corporate and social events from
concept to reality Technology, Design, Management and Humanities.</p>
                    <div className="py-0">
                        <a href="https://www.facebook.com/adoevent/" className="fa fa-facebook"></a>
                        <a href="https://twitter.com/login" className="fa fa-twitter"></a>
                        <a href="https://www.instagram.com/adoeventservices/" className="fa fa-instagram"></a>
                       
                    </div>
                </div>
            </div>  
        </div>
     </footer>
            </div>
             
        );
    }
}

export default Footer1;