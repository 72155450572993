function LoginReducer(state = {status:""}, action) {
    console.log("in login reducer ",action)
    switch (action.type) {
        case "LOGIN_SUCCESS":
            console.log("LOGIN_SUCCESS",action.getApiData);
                return {
                    ...state,
                    status:action.getApiData.data,
                      }; 
        case "LOGIN_FAILED":
            console.log("dLOGIN_FAILED",action.getApiData.data);
            return {
                ...state,
                    status:action.getApiData.data,
                };
                      
        default:
          return state
      }
}
export default LoginReducer