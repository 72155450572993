import React from 'react';
import './Componentscss.css'
import img from './mainlogo.png';





class Header extends React.Component {
  render() {
    return <div >

      <div className="socialicon">
        <div className="container">
          <div className="float-right   ">

            <a href="https://www.facebook.com/adoevent/" className="fa fa-facebook"></a>
            <a href="#" className="fa fa-twitter"></a>
            <a href="https://www.instagram.com/adoeventservices/" className="fa fa-instagram"></a><br />
          </div>
        </div>
      </div>
      <div class="container">
        <div className="header_style">
          <ul>
            <li><img src={img} className="logoimg" /></li>
            <li className="header_email"><i class="fa fa-envelope  mx-2"></i><a href="https://mail.google.com/" className="header_numbercolor" >Info.adogroup@gmail.com</a></li>
            <li><i class="fa fa-phone  mx-2 "></i> <a href="tel:+919521178777" className="header_numbercolor">+91 9521 178 777</a></li>
          </ul>
        </div>

      </div>

      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container">

            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse8">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse8">
              <div className="navbar-nav">
                <div className="header_navbar">
                  <a href="/" className="nav-item nav-font-size">HOME</a>
                  <a href="/" className="nav-item nav-font-size">PRICING</a>
                  <a href="/" className="nav-item nav-font-size">ABOUT US</a>
                  <a href="/" className="nav-item nav-font-size">CONTACT US</a>
                </div>
              </div>
            </div>

          </div>
        </nav>
      </div>



    </div>

  }
}
export default Header;