import { createStore, applyMiddleware, compose, } from "redux";
import rootReducer from "../reducer";
import createSagaMiddleware from "redux-saga";
import watcherSaga from "../sagas/loginSaga";
import RegisterSaga from "../sagas/registerSaga";
import ContactUsSaga from "../sagas/contactUsSaga";

const sagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(watcherSaga);
sagaMiddleware.run(RegisterSaga);
sagaMiddleware.run(ContactUsSaga);

export default store;
