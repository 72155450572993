import { combineReducers } from "redux";
import EventReducer from "./eventReducer";
import RegisterReducer from "./registerReducer";
import LoginReducer from "./loginReducer";
import ContactUsReducer from './contactUsReducer'

const rootReducer = combineReducers({
  events: EventReducer,
  RegisterReducer,
  LoginReducer,
  ContactUsReducer
});

export default rootReducer;
