import { takeEvery,call , put} from "redux-saga/effects"

export default function* watcherSaga(){
    console.log("data watcherSaga ")
    yield takeEvery ("LOGIN",authorize)
    // const {payload} = yield take('LOGIN');
    
}

// export function* ApiCalling(){
//     // console.log("data ApiCalling ");
//     const {payload} = yield take('LOGIN');
//     yield fork(authorize, payload);
    // console.log("data payload ",payload); 
   
    // try {       
    //     console.log("In try ApiCalling ")
    //    const getApiData = yield call(getData, "user",payload); 
    //    console.log("d<><>><><"+getApiData);      
    //     yield put({ type: "LOGIN_SUCCESS", getApiData });         
    // } catch (e) {
    //     console.log("error"+e)
    //     yield put({ type: "LOGIN_FAILED", e });         
    // }
// /
// }

 function* authorize(action) {
   
    try {
      const getApiData = yield call(getData, action)
      if(getApiData.data){
        console.log("dLOGIN_FAILED",getApiData);
        yield put({ type: "LOGIN_FAILED", getApiData });
      }else{
      
      yield put({ type: "LOGIN_SUCCESS", getApiData });
      console.log("d<><>>LOGIN_SUCCESS<",getApiData);
      }
      
    } catch(error) {
        yield put({ type: "LOGIN_FAILED", error });
    }   
  }

function getData (data){
    console.log(">>>>>>>>>><<<<<<<<<<<<<< ",data.payload)
    const {mobile} = data.payload
    if(mobile==="0809455601"){
      fetch("https://jsonplaceholder.typicode.com/posts").then(response =>
      response.json()
      );
      return{ data:"OTP sent"}
    }else{
      return {data:"invalid Number"}
    }
    
  
}
