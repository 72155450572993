import { takeEvery,call , put} from "redux-saga/effects"

export default function* ContactUsSaga(){
    console.log("data ContactUsSaga ")
    yield takeEvery ("CONTACTUS",authorize)
    // const {payload} = yield take('CONTACTUS');
    
}

 function* authorize(action) {
   
    try {
      const getApiData = yield call(getData, action)
      if(getApiData.data){
        console.log("dCONTACTUS_FAILED",getApiData);
        yield put({ type: "CONTACTUS_FAILED", getApiData });
      }else{
      
      yield put({ type: "CONTACTUS_SUCCESS", getApiData });
      console.log("d<><>>CONTACTUS_SUCCESS<",getApiData);
      }
      
    } catch(error) {
        yield put({ type: "CONTACTUS_FAILED", error });
    }   
  }

function getData (data){
    console.log(">>>>>>>>>><<<<<<<<<<<<<< ",data.payload)
    const {email, mobile} = data.payload
    console.log("email >>>>>>>>>>>>>> "+email)
    if(mobile==="0809455601" && email==="amit@annexlogics.com"){
      return fetch("https://jsonplaceholder.typicode.com/posts").then(response =>
      response.json()
      );
    }else{
      return {data:"invalid credential"}
    }
    
  
}
